<template>
  <basic-container>
    <avue-crud :option="option"
               :table-loading="loading"
               :data="data"
               :page.sync="page"
               :before-open="beforeOpen"
               v-model="form"
               ref="crud"
               @row-update="rowUpdate"
               @row-save="rowSave"
               @row-del="rowDel"
               @search-change="searchChange"
               @search-reset="searchReset"
               @selection-change="selectionChange"
               @current-change="currentChange"
               @size-change="sizeChange"
               @refresh-change="refreshChange"
               @on-load="onLoad">
      <template slot="menuLeft">
        <el-button type="danger"
                   size="small"
                   icon="el-icon-delete"
                   plain
                   v-if="permission.prnpolicyconsultreply_delete"
                   @click="handleDelete">删 除
        </el-button>
        <el-button type="primary" size="small" @click="$router.go(-1)" icon="el-icon-back">返回</el-button>
      </template>
      <template slot-scope="scope" slot="menu">
        <template v-if="scope.row.status == 1">
          <el-button
              type="text"
              size="small"
              icon="el-icon-setting"
              class="none-border"
              @click="toggleStatus(scope.row)"
              >撤回
            </el-button>
        </template>
        <template v-if="scope.row.status == 0">
          <el-button
              type="text"
              size="small"
              icon="el-icon-s-unfold"
              class="none-border"
              @click="toggleStatus(scope.row)"
              >上架
            </el-button>
        </template>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
  import {getList, getPage, getDetail, add, update, remove, updateReply} from "@/api/spcod/prnpolicyconsultreply";
  import {mapGetters} from "vuex";

var cntLenValidator = (rule, value, callback) => {
  if(value.length >= 512)
    callback(new Error('长度不能超过512字符'));
  };

  export default {
    data() {
      return {
        form: {},
        query: {},
        consultId: this.$router.currentRoute.query.consultId || 0,
        loading: true,
        page: {
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        selectionList: [],
        option: {
          height:'auto',
          calcHeight: 30,
          tip: false,
          searchShow: true,
          searchMenuSpan: 6,
          border: true,
          index: true,
          addBtn:true,
          editBtn:false,
          viewBtn: true,
          delBtn:false,
          selection: true,
          dialogClickModal: false,
          column: [
            {
              label: "咨询id",
              prop: "consultId",
              hide:true,
              addDisplay:false,
              viewDisplay:false,
              rules: [{
                required: true,
                message: "请输入咨询id",
                trigger: "blur"
              }]
            },
            {
              label: "回复内容",
              prop: "replyContent",
              span: 24,
              minRows:6,
              width : 600,
              type:"textarea",
              rules: [{
                required: true,
                message: "请输入回复内容",
                trigger: "blur",
                //validator: cntLenValidator
              }]
            },
            {
              label: "回复人",
              prop: "replierName",
              addDisplay:false,
              viewDisplay:false,
              rules: [{
                required: true,
                message: "请输入回复人id",
                trigger: "blur"
              }]
            },
            {
              label: "回复时间",
              prop: "replyTime",
              addDisplay:false,
              rules: [{
                required: true,
                message: "请输入回复时间",
                trigger: "blur"
              }]
            },
            {
              label: "状态",
              prop: "status",
              search: true,
              searchSpan: 4,
              addDisplay:false,
              editDisplay:false,
              type: 'select',
              dicData: [
                {
                  value: 1,
                  label: '正常'
                },
                {
                  value: 0,
                  label: '撤回'
                }
              ],
              rules: [{
                required: false,
                message: "状态",
                trigger: "blur"
              }]
            },
          ]
        },
        data: []
      };
    },
    computed: {
      ...mapGetters(["permission"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.prnpolicyconsultreply_add, false),
          viewBtn: this.vaildData(this.permission.prnpolicyconsultreply_view, false),
          delBtn: this.vaildData(this.permission.prnpolicyconsultreply_delete, false),
          editBtn: this.vaildData(this.permission.prnpolicyconsultreply_edit, false)
        };
      },
      ids() {
        let ids = [];
        this.selectionList.forEach(ele => {
          ids.push(ele.id);
        });
        return ids.join(",");
      }
    },
    methods: {
      rowSave(row, done, loading) {
        const _this = this;
        row.consultId = this.consultId;
        add(row).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          done();
        }, error => {
          loading();
          window.console.log(error);
        });
      },
      rowUpdate(row, index, done, loading) {
        update(row).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          done();
        }, error => {
          loading();
          console.log(error);
        });
      },
      rowDel(row) {
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            return remove(row.id);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
          });
      },
      handleDelete() {
        if (this.selectionList.length === 0) {
          this.$message.warning("请选择至少一条数据");
          return;
        }
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            return remove(this.ids);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
            this.$refs.crud.toggleSelection();
          });
      },
      beforeOpen(done, type) {
        if (["edit", "view"].includes(type)) {
          getDetail(this.form.id).then(res => {
            this.form = res.data.data;
          });
        }
        done();
      },
      searchReset() {
        this.query = {};
        this.onLoad(this.page);
      },
      searchChange(params, done) {
        this.query = params;
        this.page.currentPage = 1;
        this.onLoad(this.page, params);
        done();
      },
      selectionChange(list) {
        this.selectionList = list;
      },
      selectionClear() {
        this.selectionList = [];
        this.$refs.crud.toggleSelection();
      },
      currentChange(currentPage){
        this.page.currentPage = currentPage;
      },
      sizeChange(pageSize){
        this.page.pageSize = pageSize;
      },
      refreshChange() {
        this.onLoad(this.page, this.query);
      },
      // toggleStatus(id) {
      //   let _this = this;
      //   this.$confirm("确定改变状态？", {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //     type: "warning"
      //   })
      //     .then(() => {
      //       updateReply(id);
      //     })
      //     .then(() => {
      //       row.status = nextStatus;
      //       _this.$forceUpdate();
      //       this.onLoad(this.page);
      //       this.$message({
      //         type: "success",
      //         message: "操作成功!"
      //       });
      //       //this.$refs.crud.toggleSelection();
      //     });
      // },

      toggleStatus(row) {
        let _this = this;
        this.$confirm("确定改变状态？", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            updateReply(row.id);
          })
          .then(() => {
            // console.log(row.status)
            if (row.status === 1) {
                row.status = 0;
                this.$forceUpdate()
              }else {
                row.status = 1;
                this.$forceUpdate()
              }
            console.log(row.status)
            _this.$forceUpdate();
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
            this.$refs.crud.toggleSelection();
          });
      },
      onLoad(page, params = {}) {
        this.loading = true;
        params.consultId = this.consultId;
        getPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
          const data = res.data.data;
          this.page.total = data.total;
          this.data = data.records;
          this.loading = false;
          this.selectionClear();
        });
      }
    }
  };
</script>

<style>
</style>
